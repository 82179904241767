import styled from "styled-components";

const Star = ({ rating }) => {
	const tempStars = Array.from({ length: 5 }, (_, index) => {
		const number = index + 0.5;

		return (
			<div className="" key={index}>
				{Number(rating) > number ? (
					<svg
						width="13.33"
						height="12.68"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_518_12141)">
							<path
								d="M8.44827 1.11463C8.36404 0.943974 8.19023 0.835938 7.99992 0.835938C7.80961 0.835938 7.6358 0.943974 7.55157 1.11463L5.60779 5.0525L1.26094 5.68786C1.07266 5.71538 0.916318 5.84738 0.857629 6.02839C0.79894 6.20939 0.848076 6.40802 0.984386 6.54078L4.12932 9.60395L3.38711 13.9314C3.35494 14.119 3.43206 14.3086 3.58605 14.4205C3.74004 14.5323 3.94419 14.5471 4.11264 14.4585L7.99992 12.4142L11.8872 14.4585C12.0557 14.5471 12.2598 14.5323 12.4138 14.4205C12.5678 14.3086 12.6449 14.119 12.6127 13.9314L11.8705 9.60395L15.0155 6.54078C15.1518 6.40802 15.2009 6.20939 15.1422 6.02839C15.0835 5.84738 14.9272 5.71538 14.7389 5.68786L10.392 5.0525L8.44827 1.11463Z"
								fill="#EE9135"
								stroke="#F7C89A"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_518_12141">
								<rect width="16" height="16" fill="white" />
							</clipPath>
						</defs>
					</svg>
				) : Number(rating) > index ? (
					<svg
						width="13.33"
						height="12.68"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.44827 1.11463C8.36404 0.943974 8.19023 0.835938 7.99992 0.835938C7.80961 0.835938 7.6358 0.943974 7.55157 1.11463L5.60779 5.0525L1.26094 5.68786C1.07266 5.71538 0.916318 5.84738 0.857629 6.02839C0.79894 6.20939 0.848076 6.40802 0.984386 6.54078L4.12932 9.60395L3.38711 13.9314C3.35494 14.119 3.43206 14.3086 3.58605 14.4205C3.74004 14.5323 3.94419 14.5471 4.11264 14.4585L7.99992 12.4142L11.8872 14.4585C12.0557 14.5471 12.2598 14.5323 12.4138 14.4205C12.5678 14.3086 12.6449 14.119 12.6127 13.9314L11.8705 9.60395L15.0155 6.54078C15.1518 6.40802 15.2009 6.20939 15.1422 6.02839C15.0835 5.84738 14.9272 5.71538 14.7389 5.68786L10.392 5.0525L8.44827 1.11463Z"
							fill="#9EA6AF"
							stroke="#DFE1E4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				) : (
					<svg
						width="13.33"
						height="12.68"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.44827 1.11463C8.36404 0.943974 8.19023 0.835938 7.99992 0.835938C7.80961 0.835938 7.6358 0.943974 7.55157 1.11463L5.60779 5.0525L1.26094 5.68786C1.07266 5.71538 0.916318 5.84738 0.857629 6.02839C0.79894 6.20939 0.848076 6.40802 0.984386 6.54078L4.12932 9.60395L3.38711 13.9314C3.35494 14.119 3.43206 14.3086 3.58605 14.4205C3.74004 14.5323 3.94419 14.5471 4.11264 14.4585L7.99992 12.4142L11.8872 14.4585C12.0557 14.5471 12.2598 14.5323 12.4138 14.4205C12.5678 14.3086 12.6449 14.119 12.6127 13.9314L11.8705 9.60395L15.0155 6.54078C15.1518 6.40802 15.2009 6.20939 15.1422 6.02839C15.0835 5.84738 14.9272 5.71538 14.7389 5.68786L10.392 5.0525L8.44827 1.11463Z"
							fill="#9EA6AF"
							stroke="#DFE1E4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</div>
		);
	});
	return <Stars className="">{tempStars}</Stars>;
};

const Stars = styled.div`
	display: flex;
	gap: 3px;
	align-items: center;
`;

export default Star;
