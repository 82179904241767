export const Colors = {
  primary_color: {
    color100: "#ebf4fe",
    color200: "#c2dffc",
    color300: "#48A0F7",
    color400: "#208bf6",
    color500: "#096BCB",
    color600: "#012343"
  },
  secondary_color: {
    color100: "#FDF4EB",
    color200: "#F7C89A",
    color300: "#F5BD86",
    color400: "#F1A75D",
    color500: "#EE9135",
  },
  success_color: {
    color100: "#E8F0E8",
    color200: "#BAD1BA",
    color300: "#8DB38D",
    color400: "#488548",
    color500: "#1A661A",
  },
  warning_color: {
    color90: "#F0EEE8",
    color100: "#FAF7E6",
    color200: "#F0E6B5",
    color300: "#E7D684",
    color400: "#DDC653",
    color500: "#CEAD09",
  },
  error_color: {
    color100: "#F6E6E8",
    color200: "#E4B5BB",
    color300: "#D3848D",
    color400: "#C1525F",
    color500: "#D0320F",
  },
  neutral_color: {
    color100: "#EFF0F2",
    color200: "#DFE1E4",
    color300: "#CED3D7",
    color400: "#AEB5BC",
    color500: "#9EA6AF",
    color600: "#8E97A1",
    color700: "#7D8994",
    color800: "#6D7A86",
    color900: "#5D6B79",
    color1000: "#F5F5F5",
  },
  black: "#000000",
  white: "#ffffff",
  lightPeach: "#FFF2EC",
  darkPeach: "#FAECEF",
  skyBlue: "#EEF9FA",
  gray: "#F3F5F8",
  unknown: "#EBDCCC",
  unknown2: "#FEFCFA",
  gold: "#F7A827",
};
