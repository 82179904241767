import React from "react";

const SearchIconSvg = ({ width = "26", height = "26" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#8E97A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9999 21L16.6499 16.65"
        stroke="#8E97A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};


const LinkedInIconSvg = ({ width = "37", height = "37" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z"
        stroke="#EFF0F2"
        strokeWidth="1.5"
      />
      <path
        d="M14.4837 14.9671C15.303 14.9671 15.9672 14.3029 15.9672 13.4835C15.9672 12.6642 15.303 12 14.4837 12C13.6643 12 13.0001 12.6642 13.0001 13.4835C13.0001 14.3029 13.6643 14.9671 14.4837 14.9671Z"
        fill="#EFF0F2"
      />
      <path
        d="M15.7198 15.958H13.2473C13.1108 15.958 13 16.0688 13 16.2053V23.623C13 23.7595 13.1108 23.8703 13.2473 23.8703H15.7198C15.8563 23.8703 15.9671 23.7595 15.9671 23.623V16.2053C15.9671 16.0688 15.8563 15.958 15.7198 15.958Z"
        fill="#EFF0F2"
      />
      <path
        d="M23.088 15.5466C22.0313 15.1847 20.7094 15.5026 19.9167 16.0728C19.8895 15.9665 19.7926 15.8874 19.6774 15.8874H17.2048C17.0683 15.8874 16.9575 15.9981 16.9575 16.1346V23.5524C16.9575 23.6889 17.0683 23.7996 17.2048 23.7996H19.6774C19.8138 23.7996 19.9246 23.6889 19.9246 23.5524V18.2215C20.3242 17.8773 20.839 17.7675 21.2603 17.9465C21.6688 18.1191 21.9027 18.5404 21.9027 19.1017V23.5524C21.9027 23.6889 22.0135 23.7996 22.1499 23.7996H24.6225C24.759 23.7996 24.8698 23.6889 24.8698 23.5524V18.6037C24.8416 16.5718 23.8857 15.8196 23.088 15.5466Z"
        fill="#EFF0F2"
      />
    </svg>
  );
};


const FacebookIconSvg = ({ width = "37", height = "37" }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z"
        stroke="#EFF0F2"
        strokeWidth="1.5"
      />
      <path
        d="M20.0932 14.0205H21.7016C21.8495 14.0205 21.9696 13.9074 21.9696 13.7679V12.2524C21.9696 12.1129 21.8495 11.9998 21.7016 11.9998H20.0932C18.4677 11.9998 17.1445 13.2461 17.1445 14.7783V16.5465H15.2681C15.1201 16.5465 15 16.6597 15 16.7991V18.3147C15 18.4541 15.1201 18.5673 15.2681 18.5673H17.1445V23.8719C17.1445 24.0113 17.2646 24.1245 17.4126 24.1245H19.0209C19.1689 24.1245 19.289 24.0113 19.289 23.8719V18.5673H21.1655C21.2807 18.5673 21.3831 18.4976 21.4201 18.3945L21.9562 16.8789C21.9836 16.8021 21.9696 16.7173 21.9192 16.6511C21.8683 16.5854 21.7879 16.5465 21.7016 16.5465H19.289V14.7783C19.289 14.3605 19.6498 14.0205 20.0932 14.0205Z"
        fill="#EFF0F2"
      />
    </svg>
  );
};


const TwitterIconSvg = ({ width = "37", height = "37" }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 36C28.165 36 36 28.165 36 18.5C36 8.83502 28.165 1 18.5 1C8.83502 1 1 8.83502 1 18.5C1 28.165 8.83502 36 18.5 36Z"
        stroke="#EFF0F2"
        strokeWidth="1.5"
      />
      <path
        d="M24.2202 15.2647C24.139 15.1729 24.0081 15.1498 23.9007 15.2064C23.8339 15.2416 23.7083 15.2878 23.5592 15.3334C23.7473 15.0762 23.8985 14.7882 23.9653 14.5293C23.9942 14.4177 23.952 14.3001 23.8601 14.2336C23.7682 14.1677 23.6458 14.1677 23.5539 14.2336C23.4091 14.3375 22.7102 14.6497 22.2683 14.7508C21.2696 13.8511 20.1074 13.7577 18.8992 14.4831C17.9171 15.0729 17.7034 16.2721 17.7451 17.0031C15.4971 16.7805 14.0998 15.5593 13.3132 14.5485C13.2582 14.4776 13.1695 14.4403 13.0861 14.4458C12.9985 14.4524 12.9194 14.5024 12.8745 14.5799C12.5026 15.2257 12.3995 15.9396 12.5774 16.6447C12.6747 17.0295 12.8446 17.3686 13.0418 17.6434C12.9472 17.5956 12.8558 17.5357 12.7698 17.4642C12.6907 17.3972 12.5801 17.3845 12.4866 17.429C12.3936 17.4747 12.3343 17.5714 12.3343 17.6775C12.3343 18.8882 13.0706 19.6962 13.7583 20.1183C13.6472 20.1045 13.5312 20.0792 13.4142 20.0424C13.3138 20.0111 13.2048 20.0424 13.1353 20.1238C13.0658 20.2046 13.0493 20.32 13.0926 20.4184C13.4789 21.2972 14.2109 21.9017 15.1166 22.121C14.3258 22.5986 13.2673 22.8328 12.298 22.7179C12.1724 22.6998 12.0527 22.7817 12.0132 22.9059C11.9736 23.0301 12.0255 23.1664 12.1361 23.2301C13.6066 24.0793 14.936 24.3827 16.0849 24.3827C17.7568 24.3827 19.0472 23.7407 19.8327 23.2038C21.9503 21.7583 23.2701 19.163 23.0863 16.841C23.4256 16.5832 23.9333 16.1072 24.248 15.5955C24.3132 15.4922 24.3014 15.3559 24.2202 15.2647Z"
        fill="#EFF0F2"
      />
    </svg>
  );
};


const ExitSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#AEB5BC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#AEB5BC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};


const GoogleSvg = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3055 10.0415H21.5V10H12.5V14H18.1515C17.327 16.3285 15.1115 18 12.5 18C9.1865 18 6.5 15.3135 6.5 12C6.5 8.6865 9.1865 6 12.5 6C14.0295 6 15.421 6.577 16.4805 7.5195L19.309 4.691C17.523 3.0265 15.134 2 12.5 2C6.9775 2 2.5 6.4775 2.5 12C2.5 17.5225 6.9775 22 12.5 22C18.0225 22 22.5 17.5225 22.5 12C22.5 11.3295 22.431 10.675 22.3055 10.0415Z"
        fill="#FFC107"
      />
      <path
        d="M3.65302 7.3455L6.93851 9.755C7.82752 7.554 9.98052 6 12.5 6C14.0295 6 15.421 6.577 16.4805 7.5195L19.309 4.691C17.523 3.0265 15.134 2 12.5 2C8.65902 2 5.32802 4.1685 3.65302 7.3455Z"
        fill="#FF3D00"
      />
      <path
        d="M12.5 22C15.083 22 17.43 21.0115 19.2045 19.404L16.1095 16.785C15.0717 17.5742 13.8037 18.001 12.5 18C9.89897 18 7.69047 16.3415 6.85847 14.027L3.59747 16.5395C5.25247 19.778 8.61347 22 12.5 22Z"
        fill="#4CAF50"
      />
      <path
        d="M22.3055 10.0415H21.5V10H12.5V14H18.1515C17.7571 15.1082 17.0467 16.0766 16.108 16.7855L16.1095 16.7845L19.2045 19.4035C18.9855 19.6025 22.5 17 22.5 12C22.5 11.3295 22.431 10.675 22.3055 10.0415Z"
        fill="#1976D2"
      />
    </svg>
  );
};


const FacebookSvg = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 12C24.5 5.37262 19.1274 0 12.5 0C5.87262 0 0.5 5.37253 0.5 12C0.5 17.9895 4.88825 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1575 4.6875C16.4705 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.8398 7.875 14.375 8.80003 14.375 9.74906V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1117 22.954 24.5 17.9896 24.5 12Z"
        fill="#1877F2"
      />
      <path
        d="M17.1711 15.4688L17.7031 12H14.375V9.74906C14.375 8.79994 14.8399 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1575 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.2453 23.9514 11.8722 24.0002 12.5 24C13.1278 24.0002 13.7547 23.9514 14.375 23.8542V15.4688H17.1711Z"
        fill="white"
      />
    </svg>
  );
};

const StarSvg = ({ width = "31", height = "31", fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3352 0.680356L19.0936 8.4845C19.3024 8.70128 19.5112 8.91807 19.9288 9.13485L28.072 10.4355C28.9072 10.6523 29.3248 11.7362 28.6984 12.3866L22.852 18.4565C22.6432 18.6732 22.4344 19.1068 22.6432 19.3236L24.1048 27.7781C24.3136 28.6452 23.2696 29.2956 22.6432 28.862L15.3352 24.9599C15.1264 24.7431 14.7088 24.7431 14.2912 24.9599L6.9832 28.862C6.148 29.2956 5.3128 28.6452 5.5216 27.7781L6.9832 19.3236C6.9832 18.89 6.9832 18.6732 6.7744 18.4565L0.3016 11.953C-0.3248 11.3027 0.0928 10.2188 0.928 10.2188L9.0712 8.91807C9.4888 8.91807 9.6976 8.70128 9.9064 8.26772L13.6648 0.463575C13.8736 -0.186771 15.1264 -0.186771 15.3352 0.680356Z"
        fill={fill}
      />
    </svg>
  );
};
export { FacebookSvg, StarSvg, ExitSvg, FacebookIconSvg, GoogleSvg, LinkedInIconSvg, SearchIconSvg, TwitterIconSvg};
